import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      title:
        "Umohe Healthcare - This is medical practitioners offer their services",
      metaTags: [
        {
          name: "description",
          content: "This is medical practitioners offer their services",
        },
        {
          property: "og:description",
          content:
            "medica, medicene, hospitals, home care, laboratories, pharmacies",
        },
      ],
    },
  },
  {
    path: "/remember",
    name: "remember",
    component: () => import("../views/Remember.vue"),
    props: (route) => ({ query: route.query.q }),
    meta: {
      title:
        "Umohe Healthcare - This is medical practitioners offer their services",
      metaTags: [
        {
          name: "description",
          content: "This is medical practitioners offer their services",
        },
        {
          property: "og:description",
          content:
            "medica, medicene, hospitals, home care, laboratories, pharmacies",
        },
      ],
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/Admin.vue"),
    meta: {
      title:
        "Umohe Healthcare - This is medical practitioners offer their services",
      metaTags: [
        {
          name: "description",
          content: "This is medical practitioners offer their services",
        },
        {
          property: "og:description",
          content:
            "medica, medicene, hospitals, home care, laboratories, pharmacies",
        },
      ],
    },
    children: [
      // cms
      {
        path: "cms",
        name: "admin.cms",
        component: () => import("../views/cms/Pages"),
      },
      {
        path: "cmsp/:myId/:name",
        name: "admin.cms.pages",
        props: true,
        component: () => import("../views/cms/PageDetails.vue"),
      },
      {
        path: "dash",
        name: "admin.dash",
        component: () => import("../views/admins/dashboard.vue"),
      },
      //subscribers
      {
        path: "license",
        name: "admin.license",
        component: () =>
          import("../views/admins/subscribers/licenseVerification.vue"),
      },
      {
        path: "medics",
        name: "admin.medics",
        component: () => import("../views/admins/subscribers/medics.vue"),
      },
      {
        path: "pharm",
        name: "admin.pharmacy",
        component: () => import("../views/admins/subscribers/pharmacy.vue"),
      },
      {
        path: "labs",
        name: "admin.labs",
        component: () => import("../views/admins/subscribers/labs.vue"),
      },
      {
        path: "delivery",
        name: "admin.delivery",
        component: () => import("../views/admins/subscribers/delivery.vue"),
      },
      {
        path: "patients",
        name: "admin.patients",
        component: () => import("../views/admins/subscribers/patients.vue"),
      },
      {
        path: "medic_details/:id/:ac/:fname/:lname",
        name: "admin.medical.details",
        props: true,
        component: () => import("../views/admins/medical_details.vue"),
      },
      // users
      {
        path: "users",
        name: "admin.users",
        component: () => import("../views/admins/users/settings_users.vue"),
      },
      {
        path: "new_users",
        name: "admin.users_new",
        component: () => import("../views/admins/settings_users_new.vue"),
      },
      {
        path: "countries",
        name: "admin.setting.countries",
        component: () => import("../views/admins/settings/countries.vue"),
      },
      {
        path: "regions/:countryId/:name",
        name: "admin.setting.regions",
        component: () => import("../views/admins/settings/regions.vue"),
        props: true,
      },
      {
        path: "districts/:regionId/:name",
        name: "admin.setting.districts",
        component: () => import("../views/admins/settings/districts.vue"),
        props: true,
      },
      {
        path: "doctyes",
        name: "admin.setting.documentype",
        component: () => import("../views/admins/settings/documentype.vue"),
      },
      {
        path: "infotypes",
        name: "admin.setting.infotype",
        component: () => import("../views/admins/settings/infotype.vue"),
      },
      {
        path: "medicsys",
        name: "admin.setting.medic_systemic",
        component: () => import("../views/admins/settings/medic_systemic.vue"),
      },
      {
        path: "medicsys_details/:catId/:name",
        name: "admin.setting.medic_systemic_detail",
        component: () =>
          import("../views/admins/settings/medic_sys_cat_details.vue"),
        props: true,
      },
      {
        path: "roles",
        name: "admin.setting.roles",
        component: () => import("../views/admins/settings/roles.vue"),
      },
      {
        path: "subtypes",
        name: "admin.setting.subscribertype",
        component: () => import("../views/admins/settings/subscribertype.vue"),
      },
      {
        path: "salutations",
        name: "admin.setting.salutations",
        component: () => import("../views/admins/settings/salutations.vue"),
      },
      {
        path: "mneets",
        name: "admin.setting.meetingypes",
        component: () => import("../views/admins/settings/meetingypes.vue"),
      },
      {
        path: "subsid_type",
        name: "admin.setting.subsid_type",
        component: () => import("../views/admins/settings/subsidiarytypes.vue"),
      },
      {
        path: "measurecat",
        name: "admin.setting.measurecat",
        component: () => import("../views/admins/settings/measureCategory.vue"),
      },
      {
        path: "measures/:catId/:name",
        name: "admin.setting.measures",
        props: true,
        component: () => import("../views/admins/settings/measures.vue"),
      },
      {
        path: "access",
        name: "admin.myaccess",
        component: () => import("../views/admins/access/settings_access.vue"),
      },
      {
        path: "permits",
        name: "admin.permits",
        component: () =>
          import("../views/admins/access/settings_permissions.vue"),
      },
      {
        path: "rolepermit/:id/:name",
        name: "admin.role.permits",
        props: true,
        component: () => import("../views/admins/access/role_permits.vue"),
      },
      {
        path: "specialty",
        name: "admin.specialty",
        props: true,
        component: () => import("../views/admins/specialty/specialties.vue"),
      },
      {
        path: "details/:id/:name",
        name: "admin.specialty.details",
        props: true,
        component: () => import("../views/admins/specialty/details.vue"),
      },
      {
        path: "medicat",
        name: "admin.medicat",
        component: () => import("../views/admins/settings/medicenecat.vue"),
      },
      {
        path: "payments",
        name: "admin.billing.payments",
        component: () => import("../views/billing/payments.vue"),
      },
      // medics
      {
        path: "systemic",
        name: "admin.medics.systemic",
        component: () => import("../views/medics/systemicData.vue"),
      },
      {
        path: "systemic/details/:id/:name",
        name: "admin.medics.systemic.details",
        props: true,
        component: () => import("../views/medics/systemicDetails.vue"),
      },
      {
        path: "lab",
        name: "admin.medics.lab",
        component: () => import("../views/medics/lab/laboratoryCategory.vue"),
      },
      {
        path: "lab/detail/:id/:name",
        name: "admin.medics.lab.detail",
        props: true,
        component: () => import("../views/medics/lab/labDetails.vue"),
      },
      {
        path: "lab/detail/units/:id/:name",
        name: "admin.medics.lab.detail.units",
        props: true,
        component: () => import("../views/medics/lab/laboratoryUnits.vue"),
      },
      {
        path: "medic/freq",
        name: "admin.medic.freq",
        props: true,
        component: () => import("../views//general/medic/medicFrequency.vue"),
      },
      {
        path: "medic/route",
        name: "admin.medic.route",
        props: true,
        component: () => import("../views//general/medic/medicRoute.vue"),
      },
      // medicines
      {
        path: "mediverse",
        name: "medi.mediverse",
        component: () => import("../views/medicines/mediversion.vue"),
      },
      {
        path: "medicines",
        name: "medi.medicines",
        component: () => import("../views/medicines/medicines.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );
  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute("data-vue-router-controlled", "");
      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));
  next();
});

export default router;
