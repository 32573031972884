import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(fas);

Vue.use(Vuetify);

// export default new Vuetify({
//     icons: {
//     iconfont: 'mdi', // default - only for display purposes
//   }
// });

export default new Vuetify({
  icons: [
    {
      iconfont: "fa"
    },
    {
      iconfont: "mdi"
    },
    {
      iconfont: "mdiSvg"
    },
    {
      iconfont: "md"
    },
    {
      iconfont: "faSvg"
    }
  ]
});
