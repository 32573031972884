import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import Vuelidate from "vuelidate";
import DatetimePicker from "vuetify-datetime-picker";
import * as VueGoogleMaps from "vue2-google-maps";
import ImageUploader from "vue-image-upload-resize";
//
// http://www.mavoneditor.com/
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
Vue.use(mavonEditor);

// styles
require("./assets/Fonts/roboto-cufonfonts-webfont/style.css");
require("./assets/Fonts/helvetica-neue-lt-std-55-cufonfonts-webfont/style.css");
require("./assets/css/styles.css");
// usages
Vue.use(DatetimePicker);
Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(ImageUploader);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCxQaVG3M47ce_0LudBg3tPszNA2ztTMlE",
    libraries: "places",
  },
});
// filters
Vue.filter("myDating", function (value) {
  if (value) {
    return moment(value).format("Do MMM, YYYY hh:mm ss");
  }
});
Vue.filter("custDate", function (value) {
  if (value) {
    let date = new Date(value);
    return moment(date).format("Do MMM, YYYY");
  }
});
Vue.filter("myDate", function (value) {
  if (value) {
    return moment(value).format("Do MMM, YYYY");
  }
});

Vue.filter("myTime", function (value) {
  if (value) {
    return moment(value).format("h:mm:ss a");
  }
});

Vue.filter("capitalize", function (value) {
  if (value) {
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
